<template>
  <form @submit.prevent="logIn({ ctiStation })" class="flex items-center ml-8">
    <VlButton :mod-narrow="true" class="ml-8" @click="openLoginUrl"
      >Log in</VlButton
    >
  </form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      ctiStation: '',
    };
  },

  methods: {
    ...mapActions('cti', ['logIn']),
    openLoginUrl() {
      window.open(process.env.VUE_APP_CTI_LOGIN_URL, '_self');
    },
  },
};
</script>
