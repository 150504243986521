export default {
  bind(el, binding, vnode) {
    // Event handler for clicks outside the element
    el.__clickOutsideHandler__ = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        // Call the method passed to the directive
        binding.value(event);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', el.__clickOutsideHandler__);
  },
  unbind(el) {
    // Remove the event listener when the element is unbound
    document.removeEventListener('click', el.__clickOutsideHandler__);
    el.__clickOutsideHandler__ = null;
  },
};
